import React from 'react';
import Moment from 'moment';

import Link from 'utils/localized-link';
import Button from '@youship/components/objects/button';
import Banner from 'components/sections/banner';
import Layout from 'components/layout';
import BasicLayout from 'components/basic-layout';
import SEO from 'components/seo';
import Image from 'components/image';

import prrImage from '../../images/barra-prr.png';

const image1 = 'safy-concierge/image1.png';
const image2 = 'safy-concierge/image2.png';
const image3 = 'safy-concierge/image3.png';

const bannerImage = 'home/robot-banner-bottom2.jpg';

const bannerButtons = [
  {
    linkComponent: Link,
    linkProps: {
      to: '/contacts'
    },
    light: true,
    noArrow: true,
    small: true,
    text: 'expertise.banner.buttons.contacts'
  }
];

const SafyConciergePage = ({ pageContext: { locale } }) => (

  <Layout locale={locale}>
    <SEO title="projects.title" />
    <BasicLayout>
      <>
      <div className="">
          <img
                alt="PRR"
                src={prrImage}
              />
       </div>  
      <div>
        <p className="b-layout__center">
        Transição digital das empresas medida "VOUCHERS PARA STARTUPS - NOVOS PRODUTOS
        VERDES E DIGITAIS <br/>
        
        Youship Concierge LMD <br/>
        O presente projeto visa apostar no desenvolvimento de um projeto piloto de modo a viabilizar 
        a utilização de um robô de entregas em rotas urbanas.
        <br/>
        Investimento: 30.000,00 EUR <br/>
        </p>
      </div>
      
      <div>
        <h3>
          Objetivos do Projeto:
        </h3>
        <p>
        <ul>
          <li>
          Promoção de modelos de negócio, produtos ou serviços digitais com contributo positivo para a transição climática através de
        elevada eficiência na utilização de recursos.
          </li>
          <li>
          Promoção de modelos de negócio, produtos ou serviços digitais que permitam a redução dos impactos da poluição.
          </li>
          <li>
          Promoção de modelos de negócio, produtos ou serviços digitais que se caracterizem pela utilização de Dados Abertos ou de
Inteligência Artificial.
          </li>
        </ul>
        </p>
       </div>
    </>
    </BasicLayout>
    <Banner
      buttons={bannerButtons}
      image={bannerImage}
      title="home.banner.title"
    />

  </Layout>
);

export default SafyConciergePage;
